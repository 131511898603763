import Vue from 'vue'
import Router from 'vue-router'
const Home = () => import('./views/Home')
const Login = () => import('./views/Login')
Vue.use(Router)
const router = new Router({
    mode: 'hash',
    routes: [
        {
            path: '/',
            redirect: '/login',
        },
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/home',
            name: "home",
            meta: {
                isAuth: true
            },
            component: Home,
        }
    ]
})
router.beforeEach((to, from, next) => {
    if (to.meta.isAuth) { //判断当前路由是否需要进行权限控制
        if (sessionStorage.getItem("access_token") != null) { //权限控制的具体规则
            next()
        } else {
            alert("未登录，请先进行登录")
            next({
                path: '/login',
            })
        }
    } else {
        next() // 放行
    }
})
export default router
